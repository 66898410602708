import { mapKeys, mapValues, pickBy, startsWith } from 'lodash';

/**
 * Generate the source for `external-remotes-plugin`
 * https://github.com/module-federation/external-remotes-plugin
 *
 * @returns an object with the URLs per remote
 * @example
 * {
 *   remote_auth: 'https://ui-next.lumahealth.io/4.0.0/remote_auth/remoteEntry.js',
 *   remote_chats: 'https://ui-next.dev-main.isbuildingluma.com/remote_chats/remoteEntry.js?r=0.8664887727042525',
 *   ...
 * }
 */
function getRemoteUrls() {
  let remotes = pickBy(process.env, (_, key) => startsWith(key, 'REACT_APP_VERSION_REMOTE'));
  remotes = mapKeys(remotes, (_, key) => key.replace('REACT_APP_VERSION_', '').toLowerCase());
  remotes = mapValues(remotes, (version, key) => {
    const base = (/^http/.test(version || '') ? version : process.env.REACT_APP_REMOTES_BASE) as string;
    const baseParsed = base.replace('{name}', key).replace('{version}', version || '');
    let url = `${baseParsed}/remoteEntry.js`;
    if (url.indexOf('ui-next.lumahealth') < 0) url += `?r=${Math.random()}`;
    return url;
  });
  return remotes;
}

// @ts-expect-error
window.UINEXT_REMOTES = getRemoteUrls();

import('./App');
